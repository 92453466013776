import React from "react";
import { graphql } from "gatsby";
import Single from "./Single";

function SingleNews({ data, pageContext }) {
  return <Single data={data} pageContext={pageContext} />;
}

export default SingleNews;

export const query = graphql`
  query($slug: String!) {
    article: nodeArticle(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      drupal_id
      field_date(formatString: "MMMM Do, YYYY")
      title
      body {
        processed
        summary
      }
      relationships {
        field_tags {
          drupal_id
          name
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "news" } } }) {
      nodes {
        frontmatter {
          title
          slug
        }
      }
    }
    site {
      siteMetadata {
        drupalUrl
      }
    }
  }
`;
